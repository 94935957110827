<template>
  <div class="ALLContent">
    <div class="head">
      <div class="images"><img :src="imgurl" class="img" /></div>
    </div>

    <div class="tile">
      <div class="tileright">
        <div class="titleindex">
          <unicon name="database" width="30px" height="30px" fill="#F1664E"></unicon>
        </div>

        <div class="urlclass"><a href="#data">&nbsp;数据库</a></div>

        <div class="titleindex">
          <unicon name="analytics" width="30px" height="30px" fill="#F1664E"></unicon>
        </div>
        <div class="urlclass">
          <a href="#pbiportal">&nbsp;Power BI报表服务器自定义门户</a>
        </div>

        <div class="titleindex">
          <unicon name="analysis" width="30px" height="30px" fill="#F1664E"></unicon>
        </div>
        <div class="urlclass"><a href="#pbi">&nbsp;Power BI服务</a></div>

        <div class="titleindex">
          <unicon name="book-reader" width="30px" height="30px" fill="#F1664E"></unicon>
        </div>
        <div class="urlclass">
          <a href="/blog"> &nbsp;Blog</a>
        </div>

        <div class="titleindex">
          <unicon name="envelope-share" width="30px" height="30px" fill="#F1664E"></unicon>
        </div>
        <div class="urlclass">
          <a href="#connectus">&nbsp;联系我们</a>
        </div>
      </div>
    </div>
    <div class="coreinfo animated fadeInUp infinite" id="top" name="top">
      <p data-text="南十字数据">南十字数据</p>
      <br />
    </div>
    <div class="coreinfo1">
      <p data-text="我们很强!">我们很强!</p>
      <br />
    </div>

    <div class="content" name="data" id="data">
      <div class="datatitle">SQL Server&MySQL数据库服务</div>
      <div class="sqlcontentall">
        <div class="sqlcontent">
          <div class="sql01">
            <div class="sql01content">
              数据库运维

              <br />
              <br />
              <br />

              <unicon name="database" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">定制整体的数据库运维服务<br />
                提供完善的数据库备份方案 <br />
                提供数据库监控报告<br />
                动态掌握数据库性能状况
                <br />
                提供数据库咨询服务
                <br />提供数据库架构设计<br />
                部署方案设计
              </span>
            </div>
          </div>
        </div>
        <div class="sqlcontent">
          <div class="sql02">
            <div class="sql01content">
              数据库调优
              <br />
              <br />

              <br />
              <unicon name="analytics" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">为数据库提供完善的调优服务
                <br />
                提供性能调优培训<br />
                性能监控平台搭建
                <br />
                数据库性能报告
                <br />
                高性能数据库设计咨询<br />
              </span>
              <br />
            </div>
          </div>
        </div>
        <div class="sqlcontent">
          <div class="sql03">
            <div class="sql01content">
              数据库健康检查<br />

              <br />
              <br />
              <unicon name="panel-add" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">提供数据库系统健康检查服务<br />
                数据库最佳实践指导和培训 <br />
                系统整体优化整改建议 <br />
                <br />

                <br />

                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="sqlcontent">
          <div class="sql04">
            <div class="sql01content">
              数据库迁移和升级<br />

              <br />
              <br />
              <unicon name="arrow-up" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">旧版本升级到新版本<br />
                不同服务器之间迁移 <br />
                本地到云的迁移<br />
                云到本地迁移
                <br />
                云与云之间迁移
                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="datatitle" style="color: white">&nbsp;1111<br /></div>
    </div>

    <div class="pbicontent" name="pbiportal" id="pbiportal">
      <div class="datatitle">Power BI报表服务器自定义门户</div>
      <div class="pbicontent2">
        <div class="contentList">
          <div class="row">
            <div class="title">平台功能</div>
            <div class="titleConent">
              <div class="infoClass">
                <icon type="md-arrow-dropright" /><B>自定义身份验证：</B>
                脱离Power BI
                报表服务器默认Windows身份验证，使用自定义身份验证，使用Form方式认证，可扩展任意认证方式。
                <br />
                <icon type="md-arrow-dropright" /><B>灵活权限控制：</B>
                对报表目录和报表的访问控制，可以自定义控制，以组和用户进行组合授权。
                <br />
                <icon type="md-arrow-dropright" /><B>单点登录：</B>
                可集成现有单点登录平台，统一认证方式。<br />
                <icon type="md-arrow-dropright" /><B>统一门户：</B>
                报表阅读用户，可不使用默认Power BI
                报表服务器门户，通过自定义的报表门户，更加友好的界面，并且由自定义报表目录和报表权限，进行个性化的树形结构目录呈现。<br />

                <icon type="md-arrow-dropright" /><B>应用集成：</B>现有应用可集成Power BI报表，以嵌入方式进行嵌入，演示地址：<a
                  href="https://pbisso.datagenis.com/embed">应用集成演示</a><br />
                <icon type="md-arrow-dropright" /><B>移动端支持：</B>平台支持移动端访问。提供移动登录页面和报表页面。
                <br />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="title">使用方式</div>
            <div class="titleConent">
              <div class="infoClass">
                <icon type="md-checkmark-circle" />
                <B>基础版：</B> 提供自定义身份验证；<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                提供单点登录；<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;提供报表应用集成；<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;费用：￥30,000元起（根据需求确定最终费用）<br />
                <icon type="md-checkmark-circle" />
                <B>完整版：</B> 提供完整版功能，测试：
                <a href="https://pbisso.datagenis.com/login">登录</a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;费用：￥50,000元起（根据需求确定最终费用）<br />
                <icon type="md-checkmark-circle" />
                <B>联系方式：</B>maxcloud@outlook.com,18628037379（微信同号）
                <br />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="title">演示平台</div>
            <div class="titleConent">
              <div class="infoClass">
                <icon type="md-arrow-round-forward" /> <B>登录页面，</B>
                <a href="https://pbisso.datagenis.com/login">点击登录</a>，应用集成演示：<a
                  href="https://pbisso.datagenis.com/embed">应用集成</a><br />
                <icon type="md-arrow-round-forward" />
                <B>管理员用户：</B>Pbiadmin, 密码：123456 <br />

                <icon type="md-arrow-round-forward" />
                <B>领&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;导：</B>user01，密码：123456
                <br />
                <icon type="md-arrow-round-forward" />
                <B>财务部用户：</B>user02，密码：123456<br />
                <icon type="md-arrow-round-forward" />
                <B>综&nbsp;&nbsp;&nbsp;&nbsp;合&nbsp;&nbsp;&nbsp;&nbsp;部：</B>user03，密码：123456<br />
                <icon type="md-arrow-round-forward" />
                <B>销&nbsp;&nbsp;&nbsp;&nbsp;售&nbsp;&nbsp;&nbsp;&nbsp;部：</B>user04，密码：123456<br />
                <h3>注意：可随意修改数据，系统会30分钟恢复一次到初始状态。</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="datatitle">&nbsp;</div>
    </div>

    <div class="content" name="pbi" id="pbi">
      <div class="datatitle">Power BI 服务</div>
      <div class="sqlcontentall">
        <div class="sqlcontent">
          <div class="sql01">
            <div class="sql01content">
              Power BI培训

              <br />
              <br />
              <br />

              <unicon name="presentation-lines-alt" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">提供Power BI整体培训<br />
                DAX使用培训 <br />
                Power BI Desktop使用培训<br />
                Power Query 培训
                <br />
                其他定制化培训

                <br /><br />
              </span>
            </div>
          </div>
        </div>
        <div class="sqlcontent">
          <div class="sql02">
            <div class="sql01content">
              Power BI服务器部署
              <br />
              <br />

              <br />
              <unicon name="cog" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">Power BI 服务器方案设计
                <br />
                Power BI 服务器安装部署<br />
                Power BI 服务器运维
                <br />
                <br />
                <br />
              </span>
              <br />
            </div>
          </div>
        </div>
        <div class="sqlcontent">
          <div class="sql03">
            <div class="sql01content">
              Power BI服务器开发<br />

              <br />
              <br />
              <unicon name="code-branch" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">自定义报表门户开发<br />
                报表服务器单点登录开发 <br />
                Power BI 应用嵌入开发 <br />
                <br />

                <br />

                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
        <div class="sqlcontent">
          <div class="sql04">
            <div class="sql01content">
              Power Query开发<br />

              <br />
              <br />
              <unicon name="brackets-curly" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />

              <span class="sqlfont">Power Query 自定义开发<br />
                Power Query 应用 <br />
                <br />

                <br />

                <br />
                <br />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="content" name="connectus" id="connectus">
      <div class="datatitle">联系我们</div>
      <div class="sqlcontentall">
        <div class="connectus">
          <div class="connectuscontent02">
            <div class="connectuscontent">
              <unicon name="envelope-share" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />
              maxcloud@outlook.com

              <br />
            </div>
          </div>
        </div>
        <div class="connectus">
          <div class="connectuscontent02">
            <div class="connectuscontent">
              <unicon name="mobile-vibrate" width="80px" height="80px" fill="#F1664E"></unicon>
              <br />
              <br />
              186 2803 7379 <br />

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="datatitle" style="color: white">&nbsp;1111<br /></div>
  </div>
</template>

<script>
  import "animate.css";

  let imgurl = require("@/assets/static.jpg");
  let sql01 = require("@/assets/SQL01.jpg");
  let logourl = require("@/assets/logo.png");
  export default {
    metaInfo: {
      title: "南十字数据——我们很强！",
      meta: [
        {
          name: "description",
          content:
            "南十字数据，SQL Server规划、部署、运维、调优专家。Power BI 服务器定义身份验证开发，自定义门户，Power BI报表服务器SSO，Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发,四川SQL Server 调优，成都SQL Server 调优",
        },
        {
          name: "keywords",
          content:
            "南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发,四川SQL Server 调优，成都SQL Server 调优",
        },
        {
          name: "anthor",
          content:
            "ADDRESS: Chengdu , China Gaoxin Dist, Tianfu Five Road Jingrong International Piazza Building 4A, 12F Mail: maxcloud@outlook.com Tel:86-28-18628037379",
        },
        {
          name: "robots",
          content:
            "南十字数据,SQL Server规划,SQL Server调优,SQL Server运维,SQL Server调优专家,Power BI 服务器定义身份验证开发,自定义门户,Power BI报表服务器SSO,Power BI嵌入开发,PowerBI服务器多身份验证,Power Query开发,四川SQL Server 调优，成都SQL Server 调优",
        },
      ],
    },

    data() {
      return {
        imgurl: imgurl,
        logourl: logourl,
        sql01: sql01,
      };
    },
    mounted() { },

    methods: {},
  };
</script>

<style lang="less" scoped>
  .pbicontent2 {
    //top:50px;
    width: 94%;
    //height: 100%;
    background: white;
    margin-left: 3%;
    // margin-top: 80px;

    // transform: translateY(-50%);
    //top: 50%;
    // position: absolute;
    margin: 0 auto;
    left: 0;
    //height: 614px;

    right: 0;
    text-align: center;
    // overflow-y: auto;
  }

  .contentList {
    //overflow-y: auto;
    background-color: rgb(252, 250, 250);
  }

  .row {
    //  padding: 20px;
    border-top: 1px solid #969393;
    border-left: 1px solid #969393;
    border-bottom: 1px solid #969393;
    //padding: 10px;
    display: flex;
    margin-top: 2px;
  }

  .title {
    border-left: 1px solid #969393;
    font-size: 30px;
    // float: left;
    width: 20%;
    line-height: 200px;
    height: 200px;

    // background-color: #363535;
  }

  .infoClass {
    width: 98%;
    //background-color: darkgrey;
    margin-left: 1%;
  }

  .titleConent {
    border-left: 1px solid #969393;
    border-right: 1px solid #969393;
    line-height: 28px;
    font-size: 14px;
    //float: right;
    width: 80%;
    //background-color: aqua;
    text-align: left;
  }

  .coreinfo {
    width: 80%;
    position: absolute;
    left: 10%;
    text-align: center;
    font-size: 65px;
    top: 30%;
    font-weight: bold;
    color: black;
    // text-shadow: 2px 2px 2p tomato;
    //background-color: #e8a95b;
    background-clip: text;
  }

  .coreinfo1 {
    width: 80%;
    position: absolute;
    left: 10%;
    text-align: center;
    font-size: 40px;
    top: 45%;
    font-weight: bold;
    color: tomato;

    background-color: #e8a95b;
    background-clip: text;
  }

  p {
    position: relative;
    font-weight: bold;
    color: tomato;
    background-color: tomato;
    background-clip: text;
    // text-shadow: 2px 2px 2px rgb(192, 169, 169);

    text-shadow: 0 0 10px #0ebeff, 0 0 20px #0ebeff, 0 0 50px #0ebeff,
      0 0 100px #0ebeff, 0 0 200px #0ebeff;
  }

  p::after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg,
        transparent 0%,
        transparent 6rem,
        rgb(163, 21, 21) 11rem,
        transparent 11.15rem,
        transparent 15rem,
        rgba(255, 255, 255, 0.3) 20rem,
        transparent 25rem,
        transparent 27rem,
        rgba(255, 255, 255, 0.6) 32rem,
        rgb(240, 63, 63) 33rem,
        rgba(255, 255, 255, 0.3) 33.15rem,
        transparent 38rem,
        transparent 40rem,
        rgba(255, 255, 255, 0.3) 45rem,
        transparent 50rem,
        transparent 100%);
    background-clip: text;
    background-size: 150% 100%;
    background-repeat: no-repeat;
    animation: shine 5s infinite linear;
  }

  @keyframes shine {
    0% {
      background-position: 50% 0;
    }

    100% {
      background-position: -190% 0;
    }
  }

  .coreinfo2 {
    width: 80%;
    //  position: absolute;
    left: 10%;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgb(209, 175, 175);
    color: tomato;
  }

  .titleindex {
    padding-left: 20px;
  }

  .urlclass {
    a:link {
      text-decoration: none;
      color: rgb(248, 242, 241);
    }

    /* 未被访问的链接 蓝色 */
    a:visited {
      text-decoration: none;
      color: rgb(243, 240, 240);
    }

    /* 已被访问过的链接 蓝色 */
    a:hover {
      text-decoration: none;
      color: rgb(230, 230, 240);
    }

    /* 鼠标悬浮在上的链接 蓝色 */
    a:active {
      text-decoration: none;
      color: rgb(232, 232, 241);
    }

    /* 鼠标点中激活链接 蓝色 */
  }

  .tileright {
    //width: 80%;
    padding: 20px;
    margin-right: 10%;
    //background-color: rgb(148, 104, 1);
    text-align: right;
    line-height: 30px;
    height: 30px;
    display: flex;
    margin: auto;
  }

  .tileleft {
    width: 50%;
    margin-left: 20px;
    // background-color: rgb(219, 208, 183);
  }

  .logoclass {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .tile {
    // height: 50px;
    width: 80%;
    position: absolute;
    left: 10%;
    top: 10%;
    font-size: 20px;
    color: rgb(243, 236, 230);
    //background-color: blue;
    display: inline-flex;
    text-align: center;
  }

  .ALLContent {
    // background-color: brown;
    //  height: 400px;
    width: 100%;
    display: inline-flex;
    flex-direction: column;
  }

  .head {
    width: 100%;
    //background-color: rgb(210, 30, 186);
    height: 100%;
    //position: absolute;
  }

  .bottom {
    width: 100%;
    background-color: rgb(236, 233, 233);
    text-align: center;

    //height: 100px;

    // position: absolute;
  }

  .bottomline {
    height: 50px;
    line-height: 50px;
    font-size: 16px;
    color: rgb(121, 117, 117);
  }

  .images {
    color: black;
    z-index: 10;
  }

  .img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    filter: brightness(50%);
    z-index: -1;
    opacity: 0.9;
    //filter: contrast(40%);
  }

  .main {
    // background: transparent url("assets/static.jpg") no-repeat;
    // padding-top: 50.78%;
    width: 100%;
    height: 100%;
    border-color: chartreuse;
    display: flex;
    display: -webkit-flex;
  }

  .content1 {
    border: 0px;
    background-color: brown;
    // margin-top: 50%;
    z-index: 50;
    position: absolute;
    left: 42%;
    top: 30%;
  }

  .datatitle {
    font-size: 50px;
    line-height: 100px;
  }

  .sqlfont {
    font-size: 16px;
  }

  .connectus {
    width: 40%;
    height: 300px;
    margin-left: 5%;
    //border: tomato;
    //border: 1px solid rgb(185, 162, 162);
    background-color: rgb(250, 245, 240);
    float: left;
    color: rgb(8, 8, 8);
    font-size: 26px;
  }

  .connectuscontent02 {
    width: 90%;
    margin-left: 5%;
    height: 80%;
    margin-top: 5%;
    border: 1px solid rgb(185, 162, 162);
  }

  .connectuscontent {
    width: 100%;
    height: 70%;
    margin-top: 5%;
    border: 0px solid rgb(185, 162, 162);
    text-align: center;
    font-weight: bold;
    color: rgb(128, 133, 133);
  }

  .sqlcontent {
    width: 25%;
    height: 600px;
    //border: tomato;
    //border: 1px solid rgb(185, 162, 162);
    background-color: rgb(250, 245, 240);
    float: left;
    color: rgb(8, 8, 8);
    font-size: 26px;
  }

  .sql01 {
    width: 90%;
    margin-left: 5%;
    height: 90%;
    margin-top: 5%;
    border: 1px solid rgb(185, 162, 162);
    color: rgb(137, 141, 141);
  }

  .sql01content {
    width: 100%;
    height: 60%;
    margin-top: 20%;
    border: 0px solid rgb(185, 162, 162);
    text-align: center;
    font-weight: bold;
    color: rgb(128, 133, 133);
  }

  .sql02 {
    width: 90%;
    margin-left: 5%;
    height: 90%;
    margin-top: 5%;
    border: 1px solid rgb(185, 162, 162);
  }

  .sql03 {
    width: 90%;
    margin-left: 5%;
    height: 90%;
    margin-top: 5%;
    border: 1px solid rgb(185, 162, 162);
  }

  .sql04 {
    width: 90%;
    margin-left: 5%;
    height: 90%;
    margin-top: 5%;
    border: 1px solid rgb(185, 162, 162);
  }

  .sqlcontentall {
    width: 94%;
    margin-left: 3%;
    padding: 1px 1px 1px 1px;
    margin-top: 20px;
  }

  .content {
    width: 100%;
    //height: 700px;
    text-align: center;
  }

  .pbicontent {
    width: 100%;
    // height: 700px;
    text-align: center;
    background: darkorange;
  }

  .sqlClass {
    margin-top: 0px;
    margin-right: 0px;
    width: 100%;
    background-color: chocolate;
    position: sticky;
    height: 300px;
    display: flex;
    display: -webkit-flex;
  }
</style>
